export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '6.0.20',
    name: '@digitaservice/app',
    versionDate: '2024-11-18T17:26:15.069Z',
    gitCommitHash: 'fb7474b67',
    versionLong: '6.0.20-fb7474b67',
};
export default versions;
